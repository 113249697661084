import React from 'react';
import { AdsSection } from '@/components/AdsSection/AdsSection';
import { Footer } from '@/components/Footer/Footer';
import { TabBar } from '@/components/TabBar/TabBar';
import { PageTopControls } from '@/components/PageTopControls/PageTopControls';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@/constants/routes';
import { AndroidAppBanner } from '@/components/AndroidAppBanner/AndroidAppBanner';
import { AdOrder, GetAdsResponse, ServiceName } from '@/api/types/ads.types';
import { PremiumAdsWithInfiniteScroll } from '@/components/AdsWithInfiniteScroll/PremiumAdsWithInfiniteScroll';
import { ScrollSaver } from '@/components/ScrollSaver/ScrollSaver';
import { useGoToPageWithSaveScrollPosition } from '@/components/ScrollSaver/ScrollSaver.hooks';
import { useBanner } from '@/hooks/useBanner';
import { useBridgeState } from '@/bridge/hooks';
import { GET_ADS } from '@/api/queries/ads.queries';
import { useQuery } from '@apollo/client';
import { useUserBookmarks } from '@/hooks/bookmarks/useUserBookmarks';
import { useMobileDetect } from '@/hooks/useMobileDetect';

export const HomeView = () => {
  useUserBookmarks();
  const { isAndroid } = useMobileDetect();

  const { t } = useTranslation();
  const goToPageWithSaveScrollPosition = useGoToPageWithSaveScrollPosition();
  const { isWebView } = useBridgeState();

  const { data: adsVipData, loading: adsVipLoading } = useQuery<GetAdsResponse>(
    GET_ADS(ServiceName.Vip),
    {
      variables: {
        adKind: ServiceName.Vip,
        first: 4,
        orderType: AdOrder.Random,
      },
    }
  );

  const { data: adsLatestData, loading: adsLatestLoading } =
    useQuery<GetAdsResponse>(GET_ADS(ServiceName.Latest), {
      variables: { first: 6 },
    });

  const {
    topBanner,
    scammerBanner,
    bottomBanner: bannerAfterLatestAds,
  } = useBanner();

  return (
    <>
      <ScrollSaver />

      {!isWebView && isAndroid && <AndroidAppBanner />}

      {!isWebView && topBanner}

      <PageTopControls isMainHeader hasSearch hasCategories hasDivider />

      {!isWebView && scammerBanner}

      <AdsSection
        title={t('pages.home.vip_ads')}
        ads={adsVipData?.ads.nodes}
        adsLoading={adsVipLoading}
        onShowMoreClick={() => goToPageWithSaveScrollPosition(ROUTES.vipAds)}
        className="vipAds"
      />

      <AdsSection
        title={t('pages.home.latest_ads')}
        ads={adsLatestData?.ads.nodes}
        adsLoading={adsLatestLoading}
        hasAdManagerCard
        onShowMoreClick={() => goToPageWithSaveScrollPosition(ROUTES.ad)}
        banner={bannerAfterLatestAds}
        className="latestAds"
      />

      <PremiumAdsWithInfiniteScroll />

      <Footer />

      <TabBar />
    </>
  );
};
